<template>
  <div class="signSuccess-wrap">
    <div class="title">企业认证成功</div>
    <div class="img-wrap">
      <img src="@/assets/imgs/authenticationSuccess.png" alt />
    </div>
    <section class="des">
      <p style="text-align: center;">请在【电脑端】刷新页面，继续操作</p>
    </section>
    <van-button type="primary" class="back-button">
      自动跳转（{{ num }}）
    </van-button>
  </div>
</template>
<script>
  import ApiSign from '@/api/addSign'
  import { Dialog, Toast } from 'vant'

  export default {
    data() {
      return {
        type: this.$store.state.creditType,
        num: 3,
        timer: null,
      }
    },
    mounted() {
      const that = this
      that.timer = setInterval(() => {
        that.num -= 1
        if (that.num <= 0) {
          clearInterval(that.timer)
          that.handleBack()
        }
      }, 1000)
    },
    methods: {
      handleBack() {
        const url = this.$store.state.comSignUrl
        if (url === '/addSignNoConfirm') {
          this.addSign()
        } else {
          this.$store.dispatch('fetchUserInfo').then(() => {
            this.$router.replace({
              name: 'My',
            })
          })
        }
      },
      // 如果从加入签署页过来，直接加入签署进入详情页
      async addSign() {
        this.toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        })
        ApiSign.joinSign({
          partnerId: this.$store.state.sign.partnerId,
          comId: this.$store.state.user.userInfo.comId,
        })
          .then(async res => {
            await this.$store.dispatch('fetchUserInfo')
            const { msg, status, data } = res
            if (status === 200) {
              const { businessStatus } = data
              // 0 草稿 1未完成 2 已完成 3已作废
              if (businessStatus === 3) {
                this.$router.replace({
                  path: '/signComplete',
                })
                return
              }
              this.$router.replace({
                path: '/signdetail',
                query: {
                  businessId: data.businessId,
                },
              })
              return
            }
            Dialog.alert({
              title: '提示',
              message: msg,
            }).then(() => {})
          })
          .finally(() => {
            this.toast.clear()
          })
      },
    },
  }
</script>
<style lang="less" scoped>
  .signSuccess-wrap {
    padding-top: 16px;
    .title {
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(17, 26, 52, 1);
      line-height: 24px;
      text-align: center;
    }
    .img-wrap {
      margin: 36px auto 33px;
      width: 120px;
      height: 120px;
      img {
        width: 120px;
        height: 120px;
      }
    }

    .des {
      width: 264px;
      margin: 0 auto;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(103, 114, 131, 1);
      line-height: 24px;
      margin-bottom: 64px;
    }
    .btn {
      width: 327px;
      height: 50px;
      background: rgba(22, 118, 255, 1);
      box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
      border-radius: 25px;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 50px;
    }
    .back-button {
      width: 340px;
      position: fixed;
      bottom: 16px;
      left: 16px;
      right: 16px;
    }
  }
</style>
